.blue-button {
  padding: 10px 30px;
  background: #78d2f1;
  border-radius: 30px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 14px;
    font-weight: 600;
    color: #1b3c4c;
    text-transform: uppercase;
    white-space: nowrap;
  }
  &.disabled {
    background: #57656c;
    cursor: no-drop;
  }
}
