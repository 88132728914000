body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Tomorrow";
  src: local("Tomorrow"),
    url("./assets/fonts/Tomorrow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "TomorrowMedium";
  src: local("TomorrowMedium"),
    url("./assets/fonts/Tomorrow-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "TomorrowBold";
  src: local("TomorrowBold"),
    url("./assets/fonts/Tomorrow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "TomorrowSemiBold";
  src: local("TomorrowSemiBold"),
    url("./assets/fonts/Tomorrow-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Regular.otf");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ReactModal__Content {
  label {
    color: #78d2f1;
    font-family: Tomorrow;
    text-transform: uppercase;
  }
  .textarea-wrapper {
    margin-top: 1rem;
    .contact-textarea {
      background: #0d1d28;
      color: white;
      font-family: Tomorrow;
      padding: 10px;
      width: 480px;
      height: 120px;
      border: 1px solid #78d2f1;
      &:focus-visible {
        outline: none;
      }
    }
  }
  .button-wrapper {
    margin-top: 0.5rem;
    display: flex;
    justify-content: end;
    .blue-button {
      margin: 0;
    }
  }
}
.hidden {
  display: none;
}

.hs-button {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 50px;
  min-width: 150px;
  padding: 0 15px;

  border: none;
  border-radius: 10px;
  outline: none;
  cursor: pointer;

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;

  &.primary {
    background: rgba(120, 210, 241, 0.1);
    color: #78d2f1;
  }

  &.secondary {
    background: transparent;
    border: 2px solid #1b3a49;
    color: white;

    &:hover {
      background: rgba(120, 210, 241, 0.1);
      color: #78d2f1;
    }
  }
}

.hs-input {
  overflow: hidden;
  background: rgba(8, 20, 27, 0.6);
  border: 1px solid #1c3a4a;
  outline: none;
  border-radius: 14px;
  color: white;
  font-size: 16px;
  line-height: 22px;
  padding: 7px 20px;
  width: 240px;
}
.hs-textarea {
  display: block;
  width: 100%;
  height: 130px;
  resize: none;
  overflow: hidden;
  background: rgba(8, 20, 27, 0.6);
  border: 1px solid #1c3a4a;
  outline: none;
  border-radius: 14px;
  color: white;
  font-size: 16px;
  line-height: 22px;
  padding: 7px 20px;
}
