.chart-container {
  padding: 123px 40px 40px 320px;
  display: flex;
  height: calc(100vh - 173px);
  flex-flow: column;
  .chart-tabs-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: -4px;
    .tab-chart {
      border: 2px solid #78d2f1;
      border-bottom: none;
      cursor: pointer;
      padding: 8px 25px;
      margin-top: 6px;
      font-weight: bold;
      text-transform: uppercase;
      margin-right: -18px;
      border-top-left-radius: 20px;
      &.active {
        border-radius: 20px 20px 0 0;
        background: #78d2f1;
        color: #1b3c4c;
        padding: 11px 20px;
        margin-top: 0;
      }
    }
    .tab-text {
      border: 2px solid #78d2f1;
      border-bottom: none;
      border-top-right-radius: 20px;
      cursor: pointer;
      padding: 8px 25px;
      margin-top: 6px;
      font-weight: bold;
      text-transform: uppercase;
      &.active {
        border-radius: 20px 20px 0 0;
        background: #78d2f1;
        color: #1b3c4c;
        padding: 11px 20px;
        margin-top: 0;
      }
    }
  }
  @mixin border-base {
    width: 100%;
    height: calc(100% - 45px);
    background: #132a37;
    border: 2px solid #78d2f1;
    backdrop-filter: blur(37px);
    padding: 16px 0;
    padding-top: 0;
    .chart-wrapper {
      padding: 24px 48px;
      box-sizing: border-box;
      overflow-y: scroll;
      .icon-expand {
        position: absolute;
        top: 25px;
        right: 22px;
        width: 24px;
        height: 24px;
      }
      .line-chart-header {
        &-title {
          color: white;
          font-family: TomorrowBold;
          font-size: 28px;
        }
        &-desc {
          float: right;
          span {
            font-size: 24px;
            font-family: TomorrowMedium;
          }
          .title {
            color: white;
            margin: 0 5px 0 40px;
          }
        }
      }
      .dn-wrapper {
        display: flex;
        border-top: 3px solid #78d2f1;
        .dn-part {
          flex: 1;
          padding: 30px;
          box-sizing: content-box;
          .title {
            color: white;
            font-family: TomorrowBold;
            font-size: 28px;
            text-transform: uppercase;
            margin-bottom: 30px;
          }
          .doughnut-wrapper {
            height: 475px;
            position: relative;
          }
        }
        .separator {
          width: 1px;
          background-color: #78d2f1;
          border: 1px solid #78d2f1;
        }
      }
      .horizontal-separator {
        background-color: rgba(120, 210, 241);
        height: 3px;
        margin: 70px 0;
      }
    }
    .text-wrapper {
      height: calc(100% - 16px);
      padding: 20px;
      padding-bottom: 0;
      .text-scroll-wrapper {
        width: 100%;
        height: 100%;
        .text-header {
          display: flex;
          text-transform: uppercase;
          .large {
            color: white;
            font-family: TomorrowBold;
            font-size: 28px;
          }
          .small {
            margin-left: 10px;
            font-size: 12px;
            display: flex;
            align-items: center;
            span {
              margin-left: 5px;
            }
          }
        }
        .text-table-wrapper {
          margin-top: 20px;
          padding-bottom: 10px;
          height: calc(100% - 34px);
          position: relative;
          overflow: hidden;
          .header {
            display: flex;
            height: max-content;
            overflow-x: hidden;
            overflow-y: scroll;
            .header-item {
              padding: 0 4px;
              flex-shrink: 0;
              cursor: pointer;
              .icon-header {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: white;
                background: #1e4552;
                border-radius: 20px 20px 0 0;
                padding: 20px;
                height: 35px;
                width: -webkit-fill-available;
                position: relative;
                .column-header {
                  margin-right: 5px;
                  font-size: 17px;
                  text-transform: uppercase;
                  text-align: left;
                  &.active {
                    color: #78d2f1;
                  }
                }
              }
            }
          }
          .body {
            font-size: 15px;
            color: white;
            height: calc(100% - 100px);
            overflow: auto;
            .body-row {
              display: flex;
              .body-item {
                flex-shrink: 0;
                margin: 0 4px;
                .cell {
                  padding: 20px;
                  height: 100%;
                  box-sizing: border-box;
                  .circle {
                    width: 17px;
                    height: 17px;
                    border-radius: 17px;
                    margin-right: 5px;
                  }
                }
                .text-center {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
              &:nth-of-type(2n + 1) {
                .body-item {
                  background: #2b5b69;
                }
              }
              &:nth-of-type(2n) {
                .body-item {
                  background: #4a7886;
                }
              }
            }
          }
          .sort-dialog {
            background: #0e3846;
            box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.41);
            border-radius: 20px;
            width: 313px;
            position: absolute;
            top: 50px;
            left: 13px;
            padding: 25px;
            z-index: 1;
            .title {
              font-size: 15px;
              font-weight: 700;
              color: #78d2f1;
              text-align: left;
            }
            .switch-sort {
              display: flex;
              margin-top: 20px;
              background: #78d2f11f;
              border-radius: 43px;
              padding: 2px 0;
              .asc {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                cursor: pointer;
                .az {
                  display: flex;
                  flex-flow: column;
                  font-size: 14px;
                  span.a {
                    color: #78d2f1;
                  }
                  span.z {
                    color: #78d2f14d;
                  }
                }
                .ascending {
                  font-size: 14px;
                  font-weight: 700;
                  text-transform: none;
                  margin-left: 10px;
                  color: #fff9f94d;
                }
                &.active {
                  background: #377387;
                  margin: -4px -10px -4px 0;
                  border-radius: 43px;
                  .ascending {
                    color: white;
                  }
                }
              }
              .desc {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                cursor: pointer;
                .az {
                  display: flex;
                  flex-flow: column;
                  font-size: 14px;
                  span.a {
                    color: #78d2f14d;
                  }
                  span.z {
                    color: #78d2f1;
                  }
                }
                .descending {
                  font-size: 14px;
                  font-weight: 700;
                  text-transform: none;
                  margin-left: 10px;
                  color: #fff9f94d;
                }
                &.active {
                  background: #377387;
                  margin: -4px 0 -4px -10px;
                  border-radius: 43px;
                  .descending {
                    color: white;
                  }
                }
              }
            }
            .divider {
              background: #517688;
              height: 1px;
              margin-top: 22px;
              margin-bottom: 16px;
            }
            .filter-input-wrapper {
              background: #052934;
              border-radius: 10px;
              margin-top: 16px;
              padding: 10px 20px;
              display: flex;
              justify-content: left;
              align-items: center;
              img {
                margin-right: 10px;
              }
              input {
                font-size: 15px;
              }
            }
            .filter-check-wrapper {
              text-align: left;
              font-size: 14px;
              font-weight: 500;
              padding: 5px 15px;
              background-color: #1b4a5b;
              border-radius: 10px;
              margin-top: 10px;
              height: 260px;
              overflow-y: auto;
              .check-container {
                margin: 15px 0;
              }
              .check-title {
                display: inline-block;
                width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .filter-button-wrapper {
              margin-top: 30px;
              display: flex;
              justify-content: space-between;
              .blue-button {
                margin: 0;
              }
            }
          }
          table.text-table {
            height: calc(100% - 80px);
            border-collapse: collapse;
            border-spacing: 0;
            thead th {
              background: #132a37;
              padding: 0 4px;
              cursor: pointer;
            }
            tbody {
              tr {
                font-size: 15px;
                color: white;
                td {
                  padding: 0 4px;
                  .cell {
                    padding: 20px;
                    height: 100%;
                    box-sizing: border-box;
                    .circle {
                      width: 17px;
                      height: 17px;
                      border-radius: 17px;
                      margin-right: 5px;
                    }
                  }
                  .text-center {
                    display: flex;
                    align-items: center;
                  }
                }
                &:nth-of-type(2n + 1) {
                  td div {
                    background: #2b5b69;
                  }
                }
                &:nth-of-type(2n) {
                  td div {
                    background: #4a7886;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .border-wrapper {
    @include border-base;
    border-radius: 20px 0 20px 20px;
  }
  .border-wrapper-round {
    @include border-base;
    border-radius: 20px 20px 20px 20px;
  }
}
