.slider-container {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  .sign {
    padding: 0 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .slider-wrapper {
    width: 120px;
  }
}
.graph-popup {
  top: 0;
  left: 0;
  position: absolute;
  padding: 21px 25px;
  background: #7795fe;
  border-radius: 20px;
  color: #1c3d4d;
  font-family: Tomorrow;
  max-width: 336px;
  box-sizing: border-box;
  .account-name {
    font-family: TomorrowBold;
    font-size: 26px;
    font-weight: 700;
    line-height: 31px;
    overflow-wrap: anywhere;
  }
  .account-progress-status {
    margin-top: 8px;
    display: flex;
    align-items: center;
    .circle-progress {
      height: 30px;
    }
    span {
      margin-left: 8px;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .message-wrapper {
    width: 289px;
    max-height: 304px;
    margin-top: 20px;
    overflow-y: scroll;
    &.chart {
      max-height: 360px;
    }
    .message-item {
      display: block;
      text-decoration: none;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.7);
      padding: 8px;
      cursor: pointer;
      color: black;
      overflow: hidden;
      .message-header {
        display: flex;
        align-items: center;
        .message-avatar {
          width: 24px;
          height: 24px;
          background: white;
          border-radius: 12px;
          border: 1px solid #7795fe;
          margin-right: 8px;
          img {
            width: 24px;
            height: 24px;
            border-radius: 12px;
          }
        }
        span {
          font-size: 12px;
        }
      }
      .message-content {
        margin-top: 11px;
        font-size: 12px;
        max-height: 80px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
    .message-item:not(:first-of-type) {
      margin-top: 12px;
    }
  }
  .social-links {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 0;
    min-height: 36px;
    a {
      color: white;
      margin-right: 24px;
    }
    &.aactive {
      margin-bottom: 47px;
    }
  }
  .popup-close {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #7795fe;
  }
  .popup-like {
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    right: 20px;
    font-size: 30px;
    color: white;
    &.star {
      color: #ffd74a;
    }
    &.aactive {
      margin-bottom: 47px;
    }
  }
  .button-analysis {
    cursor: pointer;
    width: 100%;
    height: 47px;
    border-radius: 0 0 21px 21px;
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &.disabled {
      background: grey;
    }
    .button-text {
      font-family: "TomorrowBold";
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      span {
        margin-right: 10px;
      }
    }
  }
}
