.matrix-container {
  padding: 123px 40px 40px 320px;
  display: flex;
  height: calc(100vh - 213px);
  flex-flow: column;
  .tabs-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: 0px;
    .tab-chart {
      border: 2px solid #78d2f1;
      border-bottom: none;
      cursor: pointer;
      padding: 8px 25px;
      margin-top: 6px;
      font-weight: bold;
      text-transform: uppercase;
      margin-right: -18px;
      border-top-left-radius: 20px;
      &.active {
        border-radius: 20px 20px 0 0;
        background: #78d2f1;
        color: #1b3c4c;
        padding: 11px 20px;
        margin-top: 0;
      }
    }
    .tab-text {
      border: 2px solid #78d2f1;
      border-bottom: none;
      border-top-right-radius: 20px;
      cursor: pointer;
      padding: 8px 25px;
      margin-top: 6px;
      font-weight: bold;
      text-transform: uppercase;
      &.active {
        border-radius: 20px 20px 0 0;
        background: #78d2f1;
        color: #1b3c4c;
        padding: 11px 20px;
        margin-top: 0;
      }
    }
  }
  .matrix-border {
    width: 100%;
    height: 100%;
    background: #132a37;
    border: 2px solid #78d2f1;
    border-radius: 40px;
    border-top-right-radius: 0;
    box-sizing: border-box;
    padding: 40px;
    position: relative;
    .p {
      font-size: x-large;
      justify-content: center;
      display: flex;
      margin-top: 100px;
    }
    .matrix-wrapper {
      height: calc(100% - 16px);
      padding-bottom: 0;
      justify-content: space-around;
      .matrix-sort-title {
        width: 100%;
        font-size: 12px;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        .title-text {
          span {
            text-transform: uppercase;
            margin-left: 5px;
          }
        }
        .reset-sorting-wrapper {
          justify-content: flex-end;
          display: flex;
          .reset-sorting {
            float: right;
            padding: 8px 25px;
            border: 2px solid #78d2f1;
            border-radius: 10px;
            cursor: pointer;
          }
        }
      }
      .matrix-table-wrapper {
        width: fit-content;
        max-width: 100%;
        height: 100%;
        .sticky-table {
          .sticky-table-row {
            .sticky-table-cell {
              padding: 0 !important;
              background: transparent;
            }
            &:first-of-type {
              .sticky-table-cell {
                max-width: 150px;
                min-width: 150px;
                margin: 0 1px;
                .icon-header {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  color: white;
                  background: #1e4552;
                  border-radius: 20px 20px 0 0;
                  padding: 20px;
                  height: 35px;
                  width: -webkit-fill-available;
                  border-bottom: 4px solid #78d2f1;
                  div {
                    margin-right: 5px;
                    font-size: 13px;
                    text-align: left;
                    &.active {
                      color: #78d2f1;
                    }
                  }
                  img {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
        .matrix-table {
          height: 100%;
          width: 100%;
          .header {
            display: flex;
            height: max-content;
            width: 100%;
            overflow-x: hidden;
            overflow-y: scroll;
            .header-item {
              max-width: 150px;
              min-width: 150px;
              margin: 0 1px;
              .icon-header {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: white;
                background: #1e4552;
                border-radius: 20px 20px 0 0;
                padding: 20px;
                height: 35px;
                width: -webkit-fill-available;
                border-bottom: 4px solid #78d2f1;
                div {
                  margin-right: 5px;
                  font-size: 13px;
                  text-align: left;
                  &.active {
                    color: #78d2f1;
                  }
                }
                img {
                  cursor: pointer;
                }
              }
            }
          }
          .body {
            height: calc(100% - 80px);
            width: 100%;
            overflow: auto;
            position: relative;
            .body-row {
              display: flex;
              .body-item {
                max-width: 150px;
                min-width: 150px;
                color: white;
                padding: 20px 5px;
                text-align: center;
                cursor: pointer;
                box-sizing: border-box;
                margin: 0 1px;
              }
              &:nth-of-type(2n + 1) {
                .body-item {
                  background: #2b5b69;
                }
              }
              &:nth-of-type(2n) {
                .body-item {
                  background: #4a7886;
                }
              }
              &:hover {
                background: #7399a5;
                .body-item {
                  background: #7399a5;
                }
              }
            }
          }
        }
      }
    }
  }
}
