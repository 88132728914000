$primary-color: #78d2f1;

.dd-wrapper {
  cursor: pointer;
  width: 110px;
  .dd-header {
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid $primary-color;
    font-size: 18px;
    padding: 10px;
  }
  .dd-list {
    position: absolute;
    width: 110px;
    border: 1px solid #78d2f129;
    &-item {
      padding: 10px;
      text-align: center;
      font-size: 18px;
      background: #092833;
      &:hover {
        background: $primary-color;
        color: #092833;
      }
    }
  }
}

.cmc-table {
  padding: 22px 0;
  .cmc-row {
    display: flex;
    justify-content: space-between;
    .cmc-item {
      cursor: pointer;
      padding: 11px;
      &.active {
        background: $primary-color;
        border-radius: 11px;
        color: #092833;
      }
    }
  }
}
