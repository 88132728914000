$primary-color: #78d2f1;
$dark-back: #78d2f129;
$highlight-back: #78d3f13a;
.page-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  color: $primary-color;
  font-family: Tomorrow;
  #background {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .header-wrapper {
    position: relative;
    z-index: 2;
    margin: 2rem 1rem 0 1rem;
    display: flex;
    .logo-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 0;
      width: 270px;
      img {
        width: 60px;
        height: 60px;
        cursor: pointer;
      }
      label {
        color: white;
        font-family: Tomorrow;
        font-size: 26px;
        font-style: normal;
        margin-top: 0;
        margin-left: 1rem;
        text-transform: uppercase;
      }
    }
    .header-container {
      display: flex;
      flex: 1;
      .header-menu-wrapper {
        display: flex;
        background: #78d2f11f;
        backdrop-filter: blur(37px);
        border-radius: 29px;
        height: 60px;
        flex: 1;
        flex-direction: row-reverse;
        margin: auto;
        position: relative;
        .menu {
          border-left: 1px solid $primary-color;
          display: flex;
          align-items: center;
          padding: 0 1rem;
          margin: 0.75rem 0;
          .notification {
            position: relative;
            .red-point {
              position: absolute;
              right: 12px;
              background-color: #d56c69;
              width: 12px;
              height: 12px;
              border-radius: 6px;
            }
          }
          img {
            margin: 0 0.75rem;
            width: 26px;
            height: 26px;
            cursor: pointer;
          }
        }
        .status {
          border-left: 1px solid $primary-color;
          display: flex;
          align-items: center;
          padding: 0 2rem;
          margin: 0.75rem 0;
          .progress-status {
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
            .slider-wrapper {
              width: 120px;
              margin-right: 20px;
            }
            .circle-wrapper {
              display: flex;
              align-items: center;
              cursor: pointer;
            }
            .circle-progress {
              cursor: pointer;
            }
            .span-progress {
              width: 60px;
            }
            span {
              margin-left: 0.5rem;
              font-size: 13px;
              font-weight: 600;
            }
          }
          .users-status {
            display: flex;
            align-items: center;
            img {
              cursor: pointer;
            }
            span {
              margin-left: 0.5rem;
              font-size: 13px;
              font-weight: 600;
              width: 40px;
            }
          }
        }
        .search-bar {
          display: flex;
          align-items: center;
          padding: 0 2rem;
          border-left: 1px solid $primary-color;
          position: relative;
          img {
            margin-right: 0.5rem;
          }
        }
        .main-menu-head {
          margin-right: 32px;
          display: flex;
          img {
            cursor: pointer;
            width: 26px;
          }
        }
        .title-bar {
          display: flex;
          flex: 1;
          align-items: center;
          padding-left: 2rem;
          label {
            text-transform: uppercase;
          }
        }
      }
      .suggestion-list {
        position: absolute;
        width: 325px;
        max-height: 200px;
        overflow-y: auto;
        top: 70px;
        right: 282px;
        padding: 20px 34px;
        border-radius: 20px;
        background: $dark-back;
        backdrop-filter: blur(37px);
        .suggestion-item {
          cursor: pointer;
          color: white;
          font-size: 14px;
          line-height: 17px;
          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }
      }
      .menu-dropdown {
        position: absolute;
        top: 70px;
        background: #78d2f11f;
        backdrop-filter: blur(37px);
        padding: 20px 0;
        border-radius: 20px;
        .menu-item {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 15px 40px;
          color: white;
          &:hover {
            color: #1b3c4c;
            background: $primary-color;
          }
          img {
            width: 23px;
            height: 23px;
          }
          span {
            text-transform: uppercase;
            margin-left: 1rem;
          }
        }
        .separator {
          height: 1px;
          background: #5e5e5e;
          margin: 10px 0;
        }
      }
      .glossary-menu {
        position: absolute;
        top: 70px;
        width: 525px;
        max-height: 767px;
        overflow: auto;
        background: $dark-back;
        backdrop-filter: blur(37px);
        border-radius: 20px;
        .menu-title {
          display: flex;
          justify-content: center;
          padding: 18px 0;
          border-bottom: 1px solid $primary-color;
          label {
            color: $primary-color;
            font-size: 24px;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
        .menu-item {
          color: white;
          margin: 0 30px;
          text-transform: uppercase;
          border-bottom: 0px solid #186079;
          padding: 35px 15px 20px 15px;
          &:not(:last-child) {
            border-bottom-width: 1px;
          }
          .item-title {
            font-size: 20px;
            font-weight: bold;
          }
          .item-content {
            margin-top: 20px;
            font-size: 18px;
          }
        }
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.5);
          border-color: rgba(0, 0, 0, 0);
          border-style: solid;
          border-width: 25px 0;
          background-clip: padding-box;
        }
      }
      .notification-menu {
        position: absolute;
        top: 70px;
        width: 525px;
        max-height: 85vh;
        overflow: auto;
        background: $dark-back;
        backdrop-filter: blur(37px);
        border-radius: 20px;
        .menu-title {
          display: flex;
          justify-content: center;
          padding: 18px 0;
          border-bottom: 1px solid $primary-color;
          label {
            color: $primary-color;
            font-size: 24px;
            font-weight: 600;
            text-transform: uppercase;
          }
          span {
            position: absolute;
            right: 10px;
            cursor: pointer;
          }
        }
        .menu-item {
          color: white;
          padding: 10px 20px;
          border-bottom: 0px solid $primary-color;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:not(:last-child) {
            border-bottom-width: 1px;
          }
          .alert-mark {
            background-color: #d56c69;
            width: 12px;
            height: 12px;
            border-radius: 6px;
          }
          .item-content {
            cursor: pointer;
            font-size: 14px;
            width: 80%;
          }
          .msg-ago {
            font-size: 10px;
          }
          &.text-center {
            justify-content: center;
            cursor: pointer;
          }
          a {
            cursor: pointer;
          }
          &.active {
            background-color: $highlight-back;
            .item-content {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .sidemenu-container {
    position: absolute;
    top: 100px;
    left: 0;
    height: calc(100vh - 170px);
    width: 280px;
    display: flex;
    align-items: center;
    z-index: 2;
    .sidemenu-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      .menu-calendar {
        padding: 9px 25px;
        background: $dark-back;
        backdrop-filter: blur(37px);
        border-radius: 0 16px 16px 0;
        color: $primary-color;
        font-size: 14px;
        font-weight: 600;
        margin: 13px 0;
        z-index: 1;
        &-header {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
        }
        .calendar-wrapper {
          position: absolute;
          top: 50px;
          left: 0;
          background: #092833;
          padding: 40px 56px 34px 31px;
          display: flex;
          border-radius: 0 20px 20px 0;
          .part {
            width: 240px;
            &-header {
              display: flex;
              justify-content: space-between;
              label {
                align-self: center;
                font-size: 18px;
                margin-right: 60px;
                padding-left: 11px;
              }
            }
            &:not(:first-child) {
              margin-left: 55px;
            }
          }
          .calendar-ok {
            position: absolute;
            bottom: 10px;
            right: 54px;
            background: #78d2f1;
            padding: 8px 20px;
            border-radius: 8px;
            border: none;
            &:hover {
              box-shadow: 0px 0px 10px rgba(6, 159, 245, 0.5);
            }
          }
        }
      }
      .submenu-form {
        margin: 13px 0;
        position: relative;
        .submenu-title {
          height: 35px;
          background: $primary-color;
          padding-left: 30px;
          display: flex;
          align-items: center;
          border-top-right-radius: 16px;
          span {
            color: #363636;
            font-family: TomorrowSemiBold;
            font-size: 17px;
            text-transform: uppercase;
          }
        }
        .submenu-wrapper {
          padding: 0 0 10px 25px;
          background: $dark-back;
          backdrop-filter: blur(37px);
          border-bottom-right-radius: 16px;
          .submenu-contents {
            padding-right: 20px;
            display: flex;
            flex-direction: column;
            max-height: 111px;
            overflow-y: auto;
            margin-top: -1px;
          }
        }
      }
    }
  }
  .legend-container {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    .shown-menu {
      display: flex;
      width: 280px;
      justify-content: space-between;
      padding: 20px 40px;
      background: #ffffff29;
      backdrop-filter: blur(37px);
      border-radius: 0 20px 0 0;
      box-sizing: border-box;
      span {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    .hidden-menu {
      background: #ffffff29;
      backdrop-filter: blur(44px);
      border-top-right-radius: 40px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1016px;
      padding: 25px 55px;
      .legend-indicator {
        text-align: center;
      }
      .symbol-container {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        .symbol {
          display: flex;
          align-items: center;
          .symbol-text {
            line-height: 26px;
            margin-left: 20px;
            .symbol-title {
              text-transform: uppercase;
              font-size: 16px;
            }
            .symbol-desc {
              color: white;
              font-size: 14px;
            }
          }
        }
      }
      .color-container {
        display: flex;
        justify-content: space-between;
        margin-top: 42px;
        padding-bottom: 20px;
        width: 100%;
        height: 56px;
        flex-wrap: wrap;
        overflow-y: auto;
        .color-item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          min-width: 250px;
          .color-box {
            min-width: 32px;
            height: 32px;
            border-radius: 9px;
            margin-right: 11px;
          }
          .color-name {
            color: white;
          }
        }
      }
    }
    .pathway-hidden-menu {
      background: #ffffff29;
      backdrop-filter: blur(44px);
      border-top-right-radius: 40px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 600px;
      padding: 25px 55px;
      .legend-indicator {
        text-align: center;
      }
      .pathway-legend-descriptor {
        .header {
          text-transform: uppercase;
          font-size: 20px;
          font-weight: bold;
        }
        .body {
          color: white;
        }
      }
    }
  }
  .content-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    .chart-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
  .load-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #00000080;
    z-index: 100;
    display: flex;
    justify-content: center;
    .load-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.atlas-input {
  color: $primary-color;
  background: transparent;
  border: none;
  font-family: Tomorrow;
  font-size: 15px;
  outline: none;
  &::placeholder {
    color: $primary-color;
  }
  &:focus {
    outline: none;
  }
}

.submenu-contents::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.submenu-contents::-webkit-scrollbar-track {
  background: transparent;
}
.submenu-contents::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
}
.submenu-contents::-webkit-scrollbar-corner {
  background: transparent;
}
label {
  margin-bottom: 0;
}
