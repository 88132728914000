.notification-container {
  padding: 123px 40px 40px 320px;
  overflow-y: auto;
  height: calc(100vh - 163px);
  .table-container {
    min-height: calc(100% - 100px);
    background: rgba(120, 210, 241, 0.08);
    border-radius: 20px 20px 0 0;
    .table-header {
      height: 60px;
      background: rgba(120, 210, 241, 0.12);
      border-radius: 20px 20px 0 0;
      justify-content: end;
      display: flex;
      align-items: center;
      .dismiss {
        color: white;
        padding: 0px 30px;
        cursor: pointer;
      }
    }
    .table-content {
      padding: 10px;
      .notify-item {
        height: 50px;
        margin-top: 5px;
        display: flex;
        color: white;
        align-items: center;
        font-size: 14px;
        .alert-mark {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          div {
            background-color: #d56c69;
            width: 12px;
            height: 12px;
            border-radius: 6px;
            display: none;
          }
        }
        .notify-date {
          padding: 0 30px 0 10px;
        }
        &.active {
          background-color: rgba(120, 210, 241, 0.12);
          .alert-mark div {
            display: block;
          }
        }
      }
    }
  }
  .profile-table-footer {
    display: flex;
    justify-content: space-between;
    background: rgba(120, 210, 241, 0.08);
    padding: 25px;
    .profile-table-buttons {
      display: flex;
      align-items: center;
    }
    .profile-table-pagination {
      display: flex;
      align-items: center;
      img {
        margin: 10px;
      }
      span {
        color: #78d2f1;
        margin: 10px;
      }
      .move-page {
        cursor: pointer;
      }
      .page-number {
        cursor: pointer;
        &.active {
          color: white;
        }
      }
      .pagination-count {
        background: #1c3644;
        border-radius: 9px;
        border: none;
        font-size: 18px;
        color: white;
        width: 66px;
        height: 50px;
        text-align: center;
        &:focus,
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
