$primary-color: #78d2f1;
/* Customize the label (the container) */
.radio-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: white;
  .radio-title {
    text-transform: uppercase;
  }
  .radio-input:checked + .radio-title {
    color: $primary-color;
  }
  .radio-input:checked + .radio-title + .radio-percent {
    color: $primary-color;
  }
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid $primary-color;
  border-radius: 50%;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $primary-color;
}
.radio-container:last-of-type {
  margin-bottom: 1px;
}
/* The container */
.check-container:last-of-type {
  margin-bottom: 1px;
}
.check-container {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: white;
  .check-input:checked + .check-title {
    color: $primary-color;
  }
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.check-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid $primary-color;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 5px;
  top: 0;
  width: 5px;
  height: 10px;
  border: solid $primary-color;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
