.profile-container {
  padding: 123px 40px 40px 320px;
  overflow-y: auto;
  height: calc(100vh - 163px);
  .profile-table-container {
    min-height: calc(100% - 100px);
    background: rgba(120, 210, 241, 0.08);
    border-radius: 20px 20px 0 0;
  }
  table {
    width: 100%;
    backdrop-filter: blur(37px);
    border-radius: 20px 20px 0 0;
    color: white;
    border-spacing: 0;
    thead {
      tr th {
        padding: 20px;
        text-transform: uppercase;
        text-align: center;
        background: rgba(120, 210, 241, 0.12);
        border: none;
        &:first-child {
          border-radius: 20px 0 0 0;
        }
        &:last-child {
          border-radius: 0 20px 0 0;
        }
        .icon-header {
          display: inline-flex;
          align-items: center;
          span {
            margin-right: 5px;
            &.active {
              color: #78d2f1;
            }
          }
          img {
            cursor: pointer;
          }
        }
      }
    }
    tbody {
      td {
        padding: 15px 20px;
        font-size: 14px;
        text-align: center;
        align-items: center;
      }
    }
  }
  .profile-table-footer {
    display: flex;
    justify-content: space-between;
    background: rgba(120, 210, 241, 0.08);
    padding: 25px;
    .profile-table-buttons {
      display: flex;
      align-items: center;
    }
    .profile-table-pagination {
      display: flex;
      align-items: center;
      img {
        margin: 10px;
      }
      span {
        color: #78d2f1;
        margin: 10px;
      }
      .move-page {
        cursor: pointer;
      }
      .page-number {
        cursor: pointer;
        &.active {
          color: white;
        }
      }
      .pagination-count {
        background: #1c3644;
        border-radius: 9px;
        border: none;
        font-size: 18px;
        color: white;
        width: 66px;
        height: 50px;
        text-align: center;
        &:focus,
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
