.pathways-container {
  padding: 123px 40px 40px 320px;
  overflow-y: auto;
  height: calc(100vh - 163px);
  .pathways-tabs {
    display: inline-flex;
    border-radius: 20px 20px 0 0;
    background: #102330;
    .tab-header {
      width: 180px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      span {
        color: white;
        font-weight: 600;
        text-transform: uppercase;
      }
      &.active {
        background: #78d2f1;
        border-radius: 20px 20px 0 0;
        span {
          color: #1b3c4c;
        }
      }
    }
  }
  .pathways-table-container {
    height: calc(100% - 48px);
    background: rgba(120, 210, 241, 0.08);
    border-radius: 0 20px 20px 20px;
    display: flex;
    flex-flow: column;
    .pathways-table-header {
      flex: 0;
      width: 100%;
      background: rgba(120, 210, 241, 0.12);
      backdrop-filter: blur(37px);
      border-radius: 0 20px 0 0;
      display: flex;
      .icon-header {
        padding: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 25%;
        color: white;
        span {
          margin-right: 5px;
          &.active {
            color: #78d2f1;
          }
        }
        img {
          cursor: pointer;
        }
      }
    }
    .pathways-table-body-wrapper {
      flex: 1;
      padding-bottom: 20px;
      overflow-y: hidden;
      .pathways-table-body {
        height: 100%;
        overflow-y: auto;
        .pathways-table-row {
          display: flex;
          .pathways-table-td {
            width: 25%;
            padding: 20px 20px 0 20px;
            text-align: center;
            color: white;
            &:first-of-type {
              text-align: left;
              padding-left: 40px;
            }
            &:last-of-type {
              padding-right: 40px;
            }
          }
        }
      }
    }
    .profile-table-footer {
      display: flex;
      justify-content: space-between;
      padding: 25px;
      .profile-table-buttons {
        display: flex;
        align-items: center;
      }
      .profile-table-pagination {
        display: flex;
        align-items: center;
        img {
          margin: 10px;
        }
        span {
          color: #78d2f1;
          margin: 10px;
        }
        .move-page {
          cursor: pointer;
        }
        .page-number {
          cursor: pointer;
          &.active {
            color: white;
          }
        }
        .pagination-count {
          background: #1c3644;
          border-radius: 9px;
          border: none;
          font-size: 18px;
          color: white;
          width: 66px;
          height: 50px;
          text-align: center;
          &:focus,
          &:focus-visible {
            outline: none;
          }
        }
      }
    }
    table {
      width: 100%;
      backdrop-filter: blur(37px);
      border-radius: 20px 20px 0 0;
      color: white;
      border-spacing: 0;
      thead {
        tr th {
          padding: 20px;
          text-transform: uppercase;
          text-align: center;
          background: rgba(120, 210, 241, 0.12);
          border: none;
          &:last-child {
            border-radius: 0 20px 0 0;
          }
          .icon-header {
            display: inline-flex;
            align-items: center;
            span {
              margin-right: 5px;
              &.active {
                color: #78d2f1;
              }
            }
            img {
              cursor: pointer;
            }
          }
        }
      }
      tbody {
        td {
          padding: 15px 20px;
          font-size: 14px;
          text-align: center;
          align-items: center;
        }
      }
    }
  }
}
